import React, { useState } from 'react';
import {
  Box,
  SimpleGrid,
  GridItem,
  Heading,
  Text,
  Stack,
  chakra,
  FormControl,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Textarea,
  FormHelperText,
  Icon,
  Avatar,
  VisuallyHidden,
  Popover,
  Menu,
  PopoverTrigger,
  MenuButton,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  useToast,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import Card from '../../../components/Card/Card';
import { useStore } from '../../../utils/useStore';

import { Field, FormikProvider, useFormik } from 'formik';
import { PointTypes } from '../../../utils/constants';
import { ChevronDownIcon } from '@chakra-ui/icons';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import { COUNTRIES } from '../../../utils/countries';
import { ConfigViewController } from './interfaces';
import { useConfigViewController } from './config.controller';
import { Option } from 'chakra-multiselect';
import * as Yup from 'yup';
import MapComponent from '../../../extra/MapComponent';
import { Marker } from '@react-google-maps/api';

const EditFormSchema = Yup.object().shape({
  //photos: Yup.array().required('Debe subir al menos una foto'),
  name: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(50, 'Demasiado largo!')
    .required('Ingrese el nombre del Negocio'),
  email: Yup.string()
    .email('Correo invalido!')
    .required('Debe colocar un correo'),
  phone: Yup.string(),
  type: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(50, 'Demasiado largo!')
    .required('Seleccione el tipo de Negocio'),
  point: Yup.object().shape({
    lat: Yup.number().required(
      'Complete los datos de Dirección - Punto en el mapa'
    ),
    lng: Yup.number().required(
      'Complete los datos de Dirección - Punto en el mapa'
    ),
  }),
  address: Yup.string().required('Complete los datos de Dirección'),
});

const BikletaPointData = ({ formik, controller }) => {
  const [filteredBikletaPointTypes, setFilteredBikletaPointTypes] =
    useState(PointTypes);

  const countryOptions = COUNTRIES.map(({ name, iso, prefix }) => ({
    label: name,
    value: iso,
    prefix: prefix,
  }));

  const initRef = React.useRef();

  const filterMyBPsTypes = event => {
    const filteredArray = PointTypes.filter(
      item =>
        item.label.toLowerCase().includes(event.target.value.toLowerCase()) &&
        item
    );

    setFilteredBikletaPointTypes(filteredArray);
  };

  const defaultCountry = countryOptions.find(
    country => country.prefix === formik.values.phoneRegion
  );

  const getTypeLabel = typeValue =>
    PointTypes.find(item => item.value === typeValue).label;

  let debounceTimer;

  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  return (
    <FormikProvider value={formik}>
      <Box>
        <SimpleGrid
          display={{
            base: 'initial',
            md: 'grid',
          }}
          columns={{
            md: 3,
          }}
          spacing={{
            md: 6,
          }}>
          <GridItem
            colSpan={{
              md: 1,
            }}>
            <Stack px={[4, 0]} spacing={1}>
              <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
                Información comercial
              </Heading>
              <Text
                mt={1}
                fontSize="sm"
                color="gray.600"
                _dark={{
                  color: 'gray.400',
                }}>
                Esta información se mostrara publicamente a lo usuarios y
                clientes que vean tu Bikleta Point. Asegurate de colocar los
                datos correctamente.
              </Text>
            </Stack>
          </GridItem>
          <GridItem
            mt={[5, null, 0]}
            colSpan={{
              md: 2,
            }}>
            <Stack px={4} p={[null, 6]} spacing={6}>
              <SimpleGrid columns={6} spacing={4}>
                <FormControl as={GridItem} colSpan={[6, 3]}>
                  <FormLabel
                    htmlFor="name"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                      color: 'gray.50',
                    }}>
                    Nombre del Bikleta Point
                  </FormLabel>
                  <Field
                    as={Input}
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    mt={1}
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="md"
                    w="full"
                    rounded="md"
                  />
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 3]}>
                  <FormLabel
                    htmlFor="name"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                      color: 'gray.50',
                    }}>
                    Categoría del Negocio
                  </FormLabel>
                  <Popover
                    closeOnBlur={true}
                    placement="bottom"
                    initialFocusRef={initRef}>
                    {({ isOpen, onClose }) => (
                      <Box w={'100%'}>
                        <Menu>
                          <PopoverTrigger>
                            <MenuButton
                              as={Button}
                              rounded="lg"
                              borderWidth={0.5}
                              variant="outlined"
                              minW="100%"
                              maxW="100%"
                              size="md"
                              textAlign={'left'}
                              fontWeight="normal"
                              rightIcon={<ChevronDownIcon />}>
                              <Text isTruncated>
                                {getTypeLabel(formik.values.type)}
                              </Text>
                            </MenuButton>
                          </PopoverTrigger>
                        </Menu>
                        <PopoverContent w={'42vw'}>
                          <PopoverHeader w="100%">
                            <Input
                              ref={initRef}
                              type={'text'}
                              placeholder="Buscar categoría..."
                              size={'sm'}
                              rounded={'md'}
                              onChange={filterMyBPsTypes}
                            />
                          </PopoverHeader>
                          <PopoverBody
                            maxH={'40vh'}
                            minW={'100%'}
                            overflowY="auto"
                            p={0}
                            bgColor="blackAlpha.400"
                            sx={{
                              '&::-webkit-scrollbar': {
                                width: '7px',
                                borderRadius: '10px',
                                backgroundColor: `rgba(0, 0, 0, 0.1)`,
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: `rgba(0, 0, 0, 0.4)`,
                                borderRadius: '10px',
                              },
                            }}>
                            {filteredBikletaPointTypes &&
                              filteredBikletaPointTypes.map((type, key) => (
                                <Button
                                  w={'100%'}
                                  maxW={'100%'}
                                  variant="ghost"
                                  rounded={'none'}
                                  justifyContent={'flex-start'}
                                  onClick={() => {
                                    formik.setFieldValue('type', type.value);
                                    onClose();
                                  }}
                                  key={'TypeItem-' + key}
                                  isTruncated>
                                  <Text isTruncated fontWeight={'normal'}>
                                    {type.label}
                                  </Text>
                                </Button>
                              ))}
                          </PopoverBody>
                        </PopoverContent>
                      </Box>
                    )}
                  </Popover>
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 4]}>
                  <FormLabel
                    htmlFor="email_address"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                      color: 'gray.50',
                    }}>
                    Email comercial
                  </FormLabel>
                  <Field
                    as={Input}
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="email"
                    mt={1}
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="md"
                    w="full"
                    rounded="md"
                  />
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 4]}>
                  <FormLabel
                    htmlFor="email_address"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                      color: 'gray.50',
                    }}>
                    Teléfono comercial
                  </FormLabel>
                  <PhoneNumberInput
                    inputProps={{
                      size: 'md',
                      placeholder: 'Número de Teléfono',
                      rounded: 'md',
                      shadow: 'sm',
                      focusBorderColor: 'brand.400',
                      autoComplete: 'phone',
                      name: 'phone',
                      id: 'phone',
                      value: formik.values.phone,
                    }}
                    options={countryOptions}
                    country={defaultCountry?.value || ''}
                    onChange={(option: Option) => {
                      formik.setFieldValue(
                        'phone',
                        controller.getInfoFromPhoneNumber(option).phoneNumber
                      );
                      formik.setFieldValue(
                        'phoneRegion',
                        controller.getInfoFromPhoneNumber(option).region
                      );
                    }}
                  />
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 4]}>
                  <FormLabel
                    htmlFor="address"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                      color: 'gray.50',
                    }}>
                    Dirección del Negocio
                  </FormLabel>
                  <Popover
                    closeOnBlur={true}
                    placement="top"
                    initialFocusRef={initRef}>
                    {({ isOpen, onClose }) => (
                      <Box w={'100%'}>
                        <Menu>
                          <PopoverTrigger>
                            <MenuButton
                              as={Button}
                              rounded="lg"
                              borderWidth={0.5}
                              variant="outlined"
                              minW="100%"
                              maxW="100%"
                              size="md"
                              textAlign={'left'}
                              fontWeight="normal"
                              rightIcon={<ChevronDownIcon />}>
                              <Text isTruncated>
                                {formik.values.address === ''
                                  ? 'Elija su ubicación...'
                                  : formik.values.address}
                              </Text>
                            </MenuButton>
                          </PopoverTrigger>
                        </Menu>
                        <PopoverContent w={'47vw'}>
                          <PopoverHeader w="100%">
                            <Input
                              ref={initRef}
                              type={'text'}
                              placeholder="Buscar dirección..."
                              size={'sm'}
                              rounded={'md'}
                              onChange={event =>
                                debounce(() => {
                                  event.target.value !== '' &&
                                    controller.setSearchQuery(
                                      event.target.value
                                    );
                                }, 1000)
                              }
                            />
                          </PopoverHeader>
                          <PopoverBody
                            maxH={'40vh'}
                            minW={'100%'}
                            overflowY="auto"
                            p={0}
                            bgColor="blackAlpha.400"
                            sx={{
                              '&::-webkit-scrollbar': {
                                width: '7px',
                                borderRadius: '10px',
                                backgroundColor: `rgba(0, 0, 0, 0.1)`,
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: `rgba(0, 0, 0, 0.4)`,
                                borderRadius: '10px',
                              },
                            }}>
                            {controller.loadingData ? (
                              <Center my={4}>
                                <Spinner size="md" />
                              </Center>
                            ) : !controller.placesSearchResults ? null : controller
                                .placesSearchResults?.length > 0 ? (
                              controller.placesSearchResults.map(
                                (place, key) => (
                                  <Button
                                    w={'100%'}
                                    maxW={'100%'}
                                    variant="ghost"
                                    rounded={'none'}
                                    justifyContent={'flex-start'}
                                    onClick={() => {
                                      formik.setFieldValue(
                                        'address',
                                        place.formatted_address
                                      );

                                      formik.setFieldValue(
                                        'point',
                                        place.geometry.location
                                      );
                                      onClose();
                                    }}
                                    key={'TypeItem-' + key}
                                    isTruncated>
                                    <Text isTruncated fontWeight={'normal'}>
                                      {place.formatted_address}
                                    </Text>
                                  </Button>
                                )
                              )
                            ) : (
                              <Text my={4}>Sin resultados</Text>
                            )}
                          </PopoverBody>
                        </PopoverContent>
                      </Box>
                    )}
                  </Popover>
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 4]}>
                  <FormLabel>Punto en el Mapa</FormLabel>
                  <Box
                    borderRadius={20}
                    overflow="hidden"
                    borderWidth={1.5}
                    borderColor="gray.500">
                    <MapComponent
                      height={'25vh'}
                      center={
                        formik.values.point.lat && formik.values.point.lng
                          ? {
                              lat: formik.values.point.lat,
                              lng: formik.values.point.lng,
                            }
                          : null
                      }
                      zoom={
                        formik.values.point.lat && formik.values.point.lng
                          ? 15
                          : null
                      }
                      options={{
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        styles: [
                          {
                            featureType: 'poi',
                            stylers: [{ visibility: 'off' }],
                          },
                          {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }],
                          },
                          {
                            featureType: 'poi',
                            elementType: 'labels',
                            stylers: [{ visibility: 'off' }],
                          },
                          {
                            featureType: 'poi.business',
                            elementType: 'labels',
                            stylers: [{ visibility: 'off' }],
                          },
                        ],
                      }}>
                      {formik.values.point.lat && formik.values.point.lng && (
                        <Marker
                          key={'New-BP'}
                          position={{
                            lat: formik.values.point.lat,
                            lng: formik.values.point.lng,
                          }}
                        />
                      )}
                    </MapComponent>
                  </Box>
                </FormControl>

                <FormControl as={GridItem} colSpan={[6, 4]}>
                  <FormLabel
                    htmlFor="email_address"
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                      color: 'gray.50',
                    }}>
                    Sitio Web
                  </FormLabel>
                  <Field
                    as={Input}
                    type="text"
                    name="web"
                    id="web"
                    autoComplete="web"
                    mt={1}
                    focusBorderColor="brand.400"
                    shadow="sm"
                    size="md"
                    w="full"
                    rounded="md"
                  />
                </FormControl>
              </SimpleGrid>
            </Stack>
            <Box
              px={{
                base: 4,
                sm: 6,
              }}
              py={3}
              textAlign="right">
              <Button
                type="button"
                onClick={formik.submitForm}
                isLoading={controller.isSubmitting}
                colorScheme="brand"
                _focus={{
                  shadow: '',
                }}
                fontWeight="md">
                Guardar
              </Button>
            </Box>
          </GridItem>
        </SimpleGrid>
      </Box>
    </FormikProvider>
  );
};
/*
const NotificationsPreference = () => {
  return (
    <Box mt={[10, 0]}>
      <SimpleGrid
        display={{
          base: 'initial',
          md: 'grid',
        }}
        columns={{
          md: 3,
        }}
        spacing={{
          md: 6,
        }}>
        <GridItem
          colSpan={{
            md: 1,
          }}>
          <Box px={[4, 0]}>
            <Text
              mt={1}
              fontSize="sm"
              color="gray.600"
              _dark={{
                color: 'gray.400',
              }}>
              Decide que notificaciones quieres recibir y de que forma.
            </Text>
          </Box>
        </GridItem>
        <GridItem
          mt={[5, null, 0]}
          colSpan={{
            md: 2,
          }}>
          <chakra.form
            method="POST"
            shadow="base"
            rounded={'lg'}
            overflow={{
              sm: 'hidden',
            }}>
            <Stack
              px={4}
              py={5}
              p={[null, 6]}
              bg="white"
              _dark={{
                bg: '#141517',
              }}
              spacing={6}>
              <chakra.fieldset>
                <Box
                  as="legend"
                  fontSize="md"
                  color="gray.900"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  Notificaciones por Email
                </Box>
                <Stack mt={4} spacing={4}>
                  <Flex alignItems="start">
                    <Flex alignItems="center" h={5}>
                      <Checkbox
                        colorScheme="brand"
                        id="comments"
                        rounded="md"
                      />
                    </Flex>
                    <Box ml={3} fontSize="sm">
                      <chakra.label
                        htmlFor="comments"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        Resumen de Calificaciónes
                      </chakra.label>
                      <Text
                        color="gray.500"
                        _dark={{
                          color: 'gray.400',
                        }}>
                        Reciba un estado semanal de su Bikleta Point, que opinan
                        sus clientes, y consejos para aumentar su exposición.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="start">
                    <Flex alignItems="center" h={5}>
                      <Checkbox
                        colorScheme="brand"
                        id="candidates"
                        rounded="md"
                      />
                    </Flex>
                    <Box ml={3} fontSize="sm">
                      <chakra.label
                        htmlFor="candidates"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        Estado de Solicitudes
                      </chakra.label>
                      <Text
                        color="gray.500"
                        _dark={{
                          color: 'gray.400',
                        }}>
                        Reciba notificaciones cuando haya cambios en las
                        solicitudes que haya emitído.
                      </Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="start">
                    <Flex alignItems="center" h={5}>
                      <Checkbox colorScheme="brand" id="offers" rounded="md" />
                    </Flex>
                    <Box ml={3} fontSize="sm">
                      <chakra.label
                        htmlFor="offers"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        Novedades
                      </chakra.label>
                      <Text
                        color="gray.500"
                        _dark={{
                          color: 'gray.400',
                        }}>
                        Enterese de las novedades que trae Bikleta para su
                        negocio.
                      </Text>
                    </Box>
                  </Flex>
                </Stack>
              </chakra.fieldset>
              <chakra.fieldset>
                <Box
                  as="legend"
                  fontSize="md"
                  color="gray.900"
                  _dark={{
                    color: 'gray.50',
                  }}>
                  Push Notifications
                  <Text
                    fontSize="sm"
                    color="gray.500"
                    _dark={{
                      color: 'gray.400',
                    }}>
                    These are delivered via SMS to your mobile phone.
                  </Text>
                </Box>
                <RadioGroup
                  fontSize="sm"
                  color="gray.700"
                  _dark={{
                    color: 'gray.50',
                  }}
                  colorScheme="brand"
                  mt={4}
                  defaultValue="1">
                  <Stack spacing={4}>
                    <Radio spacing={3} value="1">
                      Everything
                    </Radio>
                    <Radio spacing={3} value="2">
                      Same as email
                    </Radio>
                    <Radio spacing={3} value="3">
                      No push notifications
                    </Radio>
                  </Stack>
                </RadioGroup>
              </chakra.fieldset>
            </Stack>
            <Box
              px={{
                base: 4,
                sm: 6,
              }}
              py={3}
              bg="gray.50"
              _dark={{
                bg: '#121212',
              }}
              textAlign="right">
              <Button
                type="submit"
                colorScheme="brand"
                _focus={{
                  shadow: '',
                }}
                fontWeight="md">
                Guardar
              </Button>
            </Box>
          </chakra.form>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
*/
const ConfigView: React.FC<ConfigViewController> = ({
  useController = useConfigViewController,
}) => {
  const { activePoint } = useStore();

  const controller = useController();

  const toast = useToast();

  const handleSubmit = async values => {
    try {
      await controller.submitEditedData(activePoint._id, values);

      toast({
        title: `Comercio Editado!`,
        description: `${values.name} se editó exitosamente.`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Ocurrió un error`,
        description: `${values.name} no se pudo editar.`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: activePoint && {
      name: activePoint.name,
      type: activePoint.type,
      email: activePoint.email,
      phone: activePoint?.phone || '',
      phoneRegion: activePoint?.phoneRegion || '',
      address: activePoint.address,
      point: activePoint.point,
      web: activePoint.web,
      workTime: activePoint.workTime,
      images: activePoint.images,
    },
    validationSchema: EditFormSchema,
    onSubmit: values => handleSubmit(values),
    enableReinitialize: true,
  });

  return (
    activePoint && (
      <Card
        rounded="lg"
        mt={{ base: '120px', md: '75px' }}
        p={10}
        bgColor="#27273A">
        <Box hidden>
          <SimpleGrid
            display={{
              base: 'initial',
              md: 'grid',
            }}
            columns={{
              md: 3,
            }}
            spacing={{
              md: 6,
            }}>
            <GridItem
              colSpan={{
                md: 1,
              }}>
              <Box px={[4, 0]}>
                <Heading fontSize="lg" fontWeight="md" lineHeight="6">
                  Datos de Contacto
                </Heading>
                <Text
                  mt={1}
                  fontSize="sm"
                  color="gray.600"
                  _dark={{
                    color: 'gray.400',
                  }}>
                  Información de contacto de la persona responsable del bikleta
                  point.
                </Text>
              </Box>
            </GridItem>
            <GridItem
              mt={[5, null, 0]}
              colSpan={{
                md: 2,
              }}>
              <chakra.form
                method="POST"
                shadow="base"
                rounded={'lg'}
                overflow={{
                  sm: 'hidden',
                }}>
                <Stack
                  px={4}
                  py={5}
                  bg="white"
                  _dark={{
                    bg: '#141517',
                  }}
                  spacing={6}
                  p={{
                    sm: 6,
                  }}>
                  <SimpleGrid columns={3} spacing={6}>
                    <FormControl as={GridItem} colSpan={[3, 2]}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        Website
                      </FormLabel>
                      <InputGroup size="sm">
                        <InputLeftAddon
                          bg="gray.50"
                          _dark={{
                            bg: 'gray.800',
                          }}
                          color="gray.500"
                          rounded="md">
                          http://
                        </InputLeftAddon>
                        <Input
                          type="tel"
                          placeholder="www.example.com"
                          focusBorderColor="brand.400"
                          rounded="md"
                        />
                      </InputGroup>
                    </FormControl>
                  </SimpleGrid>
                  <div>
                    <FormControl id="email" mt={1}>
                      <FormLabel
                        fontSize="sm"
                        fontWeight="md"
                        color="gray.700"
                        _dark={{
                          color: 'gray.50',
                        }}>
                        About
                      </FormLabel>
                      <Textarea
                        placeholder="you@example.com"
                        mt={1}
                        rows={3}
                        shadow="sm"
                        focusBorderColor="brand.400"
                        fontSize={{
                          sm: 'sm',
                        }}
                      />
                      <FormHelperText>
                        Brief description for your profile. URLs are
                        hyperlinked.
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <FormControl>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: 'gray.50',
                      }}>
                      Photo
                    </FormLabel>
                    <Flex alignItems="center" mt={1}>
                      <Avatar
                        boxSize={12}
                        bg="gray.100"
                        _dark={{
                          bg: 'gray.800',
                        }}
                        icon={
                          <Icon
                            as={FaUser}
                            boxSize={9}
                            mt={3}
                            rounded="full"
                            color="gray.300"
                            _dark={{
                              color: 'gray.700',
                            }}
                          />
                        }
                      />
                      <Button
                        type="button"
                        ml={5}
                        variant="outline"
                        size="sm"
                        fontWeight="medium"
                        _focus={{
                          shadow: 'none',
                        }}>
                        Change
                      </Button>
                    </Flex>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="md"
                      color="gray.700"
                      _dark={{
                        color: 'gray.50',
                      }}>
                      Cover photo
                    </FormLabel>
                    <Flex
                      mt={1}
                      justify="center"
                      px={6}
                      pt={5}
                      pb={6}
                      borderWidth={2}
                      _dark={{
                        color: 'gray.500',
                      }}
                      borderStyle="dashed"
                      rounded="md">
                      <Stack spacing={1} textAlign="center">
                        <Icon
                          mx="auto"
                          boxSize={12}
                          color="gray.400"
                          _dark={{
                            color: 'gray.500',
                          }}
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true">
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </Icon>
                        <Flex
                          fontSize="sm"
                          color="gray.600"
                          _dark={{
                            color: 'gray.400',
                          }}
                          alignItems="baseline">
                          <chakra.label
                            htmlFor="file-upload"
                            cursor="pointer"
                            rounded="md"
                            fontSize="md"
                            color="brand.600"
                            _dark={{
                              color: 'brand.200',
                            }}
                            pos="relative"
                            _hover={{
                              color: 'brand.400',
                              _dark: {
                                color: 'brand.300',
                              },
                            }}>
                            <span>Upload a file</span>
                            <VisuallyHidden>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                              />
                            </VisuallyHidden>
                          </chakra.label>
                          <Text pl={1}>or drag and drop</Text>
                        </Flex>
                        <Text
                          fontSize="xs"
                          color="gray.500"
                          _dark={{
                            color: 'gray.50',
                          }}>
                          PNG, JPG, GIF up to 10MB
                        </Text>
                      </Stack>
                    </Flex>
                  </FormControl>
                </Stack>
                <Box
                  px={{
                    base: 4,
                    sm: 6,
                  }}
                  py={3}
                  bg="gray.50"
                  _dark={{
                    bg: '#121212',
                  }}
                  textAlign="right">
                  <Button
                    type="submit"
                    colorScheme="brand"
                    _focus={{
                      shadow: '',
                    }}
                    fontWeight="md">
                    Guardar
                  </Button>
                </Box>
              </chakra.form>
            </GridItem>
          </SimpleGrid>
        </Box>
        <BikletaPointData formik={formik} controller={controller} />
      </Card>
    )
  );
};

export default ConfigView;
