import React, { useState } from 'react';
import {
  Button,
  Flex,
  Image,
  Stack,
  Text,
  Center,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  GridItem,
  SimpleGrid,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverBody,
  Switch,
  Collapse,
  useToast,
  IconButton,
  Menu,
  MenuButton,
  Spinner,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
} from '@chakra-ui/react';
import { AddBikletaPointSteps } from '../../../assets';
import { Field, FormikProvider, getIn, useFormik } from 'formik';
import { Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as Yup from 'yup';
import { daysArray, PointTypes } from '../../../utils/constants';

import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './styles.css';
import MapComponent from '../../../extra/MapComponent';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useBikletaPointsService } from '../../../services/bikletaPoints/bikletaPointsService';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { AddBikletaPointProps, FormValues, StepItem } from './interfaces';
import { useFormSectionController } from './formStepper.controller';
import { Marker } from '@react-google-maps/api';
import PhoneNumberInput from '../../PhoneNumberInput';
import { COUNTRIES } from '../../../utils/countries';
import { Option } from 'chakra-multiselect';
import { useStore } from '../../../utils/useStore';
import { BiLogOut } from 'react-icons/bi';
import LogOutmodal from '../LogOutModal';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

const SignupSchema = Yup.object().shape({
  photos: Yup.array().required('Debe subir al menos una foto'),
  name: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(50, 'Demasiado largo!')
    .required('Ingrese el nombre del Negocio'),
  email: Yup.string()
    .email('Correo invalido!')
    .required('Debe colocar un correo'),
  phone: Yup.string(),
  type: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(50, 'Demasiado largo!')
    .required('Seleccione el tipo de Negocio'),
  point: Yup.object().shape({
    lat: Yup.number().required(
      'Complete los datos de Dirección - Punto en el mapa'
    ),
    lng: Yup.number().required(
      'Complete los datos de Dirección - Punto en el mapa'
    ),
  }),
  address: Yup.string().required('Complete los datos de Dirección'),
});

const OpenTimeCheck = ({ dayName, index, formik }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Stack spacing={3} direction="row" align="center" textAlign={'left'}>
        <Text w="15%" fontSize={'sm'}>
          {dayName}
        </Text>
        <Stack direction={'row'} spacing={2} align="center">
          <Field
            as={Switch}
            name={'workTime.' + index + '.opens'}
            size="sm"
            colorScheme={'yellow'}
            onChange={e => {
              formik.setFieldValue('workTime.' + index + '.opens', !isOpen);
              setIsOpen(!isOpen);
            }}
          />
          <Text fontSize={'sm'}>{isOpen ? 'Abierto' : 'Cerrado'}</Text>
        </Stack>
      </Stack>
      <Collapse in={isOpen} animateOpacity>
        <Stack
          mt="0.5"
          rounded="md"
          direction={'row'}
          spacing={10}
          maxW={'50%'}>
          <FormControl isRequired as={GridItem} colSpan={3}>
            <FormLabel htmlFor="openingTime" fontSize={'x-small'}>
              Horario de Apertura
            </FormLabel>
            <Field
              as={Input}
              borderColor="brand.50"
              variant="flushed"
              id="openingTime"
              size="sm"
              name={'workTime.' + index + '.openTime'}
              type="time"
              placeholder="Horario de Apertura"
            />
          </FormControl>

          <FormControl isRequired as={GridItem} colSpan={3}>
            <FormLabel htmlFor="closingTime" fontSize={'x-small'}>
              Horario de Cierre
            </FormLabel>
            <Field
              as={Input}
              borderColor="brand.50"
              variant="flushed"
              id="closingTime"
              size="sm"
              name={'workTime.' + index + '.closeTime'}
              type="time"
              placeholder="Horario de Cierre"
            />
          </FormControl>
        </Stack>
      </Collapse>
    </>
  );
};

const FirstStepItem: React.FC<StepItem> = ({ formik, action }) => {
  const [filteredBikletaPointTypes, setFilteredBikletaPointTypes] =
    useState(PointTypes);
  const initRef = React.useRef();

  const filterMyBPsTypes = event => {
    const filteredArray = PointTypes.filter(
      item =>
        item.label.toLowerCase().includes(event.target.value.toLowerCase()) &&
        item
    );

    setFilteredBikletaPointTypes(filteredArray);
  };

  const getTypeLabel = typeValue =>
    PointTypes.find(item => item.value === typeValue).label;

  return (
    <FormikProvider value={formik}>
      <Stack w="100%" direction={{ base: 'column', md: 'row-reverse' }} px="2%">
        <Flex
          py={8}
          flex={1}
          align={'center'}
          justify={'left'}
          pos="relative"
          w="60%">
          <Stack w="100%">
            <SimpleGrid
              display={'grid'}
              w="100%"
              columns={{ md: 7 }}
              spacing={{ sm: 2, md: 10 }}
              px={0}>
              <Stack textAlign={'left'} as={GridItem} colSpan={4} spacing={5}>
                <Text fontSize={'3xl'}>Comienza a crear tu Bikleta Point</Text>
                <Text fontSize={'md'}>
                  De este modo tendrás mayor visibilidad dentro de la comunidad
                  Bikleta
                </Text>
              </Stack>
              <FormControl
                isInvalid={getIn(formik.errors, 'name')}
                isRequired
                as={GridItem}
                colSpan={6}>
                <Field
                  as={Input}
                  variant="filled"
                  size="lg"
                  placeholder="Nombre de mi Negocio"
                  id="name"
                  name="name"
                  type="text"
                />
                {getIn(formik.errors, 'name') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(formik.errors, 'name')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>

              <GridItem colSpan={6}>
                <FormControl
                  mb={5}
                  isInvalid={getIn(formik.errors, 'type')}
                  isRequired>
                  <Popover
                    closeOnBlur={false}
                    placement="top"
                    initialFocusRef={initRef}>
                    {({ isOpen, onClose }) => (
                      <Box w={'100%'}>
                        <Menu>
                          <PopoverTrigger>
                            <MenuButton
                              as={Button}
                              rounded="lg"
                              minW="100%"
                              maxW="100%"
                              size="lg"
                              textAlign={'left'}
                              fontWeight="normal"
                              rightIcon={<ChevronDownIcon />}>
                              <Text isTruncated>
                                {formik.values.type === ''
                                  ? 'Seleccione la categoría...'
                                  : getTypeLabel(formik.values.type)}
                              </Text>
                            </MenuButton>
                          </PopoverTrigger>
                        </Menu>
                        <PopoverContent
                          w={'47vw'}
                          zIndex={1000}
                          className="swiper-no-swiping">
                          <PopoverHeader w="100%">
                            <Input
                              ref={initRef}
                              type={'text'}
                              placeholder="Buscar categoría..."
                              size={'sm'}
                              rounded={'md'}
                              onChange={filterMyBPsTypes}
                            />
                          </PopoverHeader>
                          <PopoverBody
                            maxH={'40vh'}
                            minW={'100%'}
                            overflowY="auto"
                            p={0}
                            bgColor="blackAlpha.400"
                            sx={{
                              '&::-webkit-scrollbar': {
                                width: '7px',
                                borderRadius: '10px',
                                backgroundColor: `rgba(0, 0, 0, 0.1)`,
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: `rgba(0, 0, 0, 0.4)`,
                                borderRadius: '10px',
                              },
                            }}>
                            {filteredBikletaPointTypes &&
                              filteredBikletaPointTypes.map((type, key) => (
                                <Button
                                  w={'100%'}
                                  maxW={'100%'}
                                  variant="ghost"
                                  rounded={'none'}
                                  justifyContent={'flex-start'}
                                  onClick={() => {
                                    formik.setFieldValue('type', type.value);
                                    onClose();
                                  }}
                                  key={'TypeItem-' + key}
                                  isTruncated>
                                  <Text isTruncated fontWeight={'normal'}>
                                    {type.label}
                                  </Text>
                                </Button>
                              ))}
                          </PopoverBody>
                        </PopoverContent>
                      </Box>
                    )}
                  </Popover>
                  {getIn(formik.errors, 'type') ? (
                    <FormErrorMessage fontSize={'x-small'}>
                      {getIn(formik.errors, 'type')}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </GridItem>

              <GridItem colSpan={2}>
                <Button
                  disabled={!(formik.values?.name && formik.values?.type)}
                  w="100%"
                  bgColor={'#313149'}
                  color="white"
                  rounded="full"
                  onClick={action}>
                  Siguiente
                </Button>
              </GridItem>
            </SimpleGrid>
          </Stack>
        </Flex>
        <Center h="80vh" w="40%">
          <Image
            alt={'Step Image'}
            src={AddBikletaPointSteps[0]}
            maxH="100%"
            objectFit={'contain'}
          />
        </Center>
      </Stack>
    </FormikProvider>
  );
};

const SecondStepItem: React.FC<StepItem> = ({ formik, action, controller }) => {
  const initRef = React.useRef();
  let debounceTimer;

  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  return (
    <FormikProvider value={formik}>
      <Stack w="100%" direction={{ base: 'column', md: 'row-reverse' }} px="2%">
        <Flex
          w="60%"
          py={8}
          flex={1}
          align={'center'}
          justify={'left'}
          pos="relative">
          <Stack w="100%">
            <SimpleGrid
              display={'grid'}
              w="100%"
              columns={{ md: 7 }}
              spacing={{ sm: 2, md: 10 }}
              px={0}>
              <Stack textAlign={'left'} as={GridItem} colSpan={4} spacing={5}>
                <Text fontSize={'2xl'}>
                  Indica cuál es la dirección de tu comercio
                </Text>
              </Stack>
              <SimpleGrid columns={6} spacing={4} as={GridItem} colSpan={6}>
                <FormControl
                  mb={5}
                  as={GridItem}
                  colSpan={{ sm: 6, md: 6 }}
                  style={{ zIndex: 1000 }}
                  isInvalid={getIn(formik.errors, 'address')}
                  isRequired>
                  <Popover
                    closeOnBlur={false}
                    placement="top"
                    initialFocusRef={initRef}>
                    {({ isOpen, onClose }) => (
                      <Box w={'100%'}>
                        <Menu>
                          <PopoverTrigger>
                            <MenuButton
                              as={Button}
                              rounded="lg"
                              minW="100%"
                              maxW="100%"
                              size="lg"
                              textAlign={'left'}
                              fontWeight="normal"
                              rightIcon={<ChevronDownIcon />}>
                              <Text isTruncated>
                                {formik.values.address === ''
                                  ? 'Elija su ubicación...'
                                  : formik.values.address}
                              </Text>
                            </MenuButton>
                          </PopoverTrigger>
                        </Menu>
                        <PopoverContent w={'47vw'}>
                          <PopoverHeader w="100%">
                            <Input
                              ref={initRef}
                              type={'text'}
                              placeholder="Buscar dirección..."
                              size={'sm'}
                              rounded={'md'}
                              onChange={event =>
                                debounce(() => {
                                  event.target.value !== '' &&
                                    controller.setSearchQuery(
                                      event.target.value
                                    );
                                }, 1000)
                              }
                            />
                          </PopoverHeader>
                          <PopoverBody
                            maxH={'40vh'}
                            minW={'100%'}
                            overflowY="auto"
                            p={0}
                            bgColor="blackAlpha.400"
                            sx={{
                              '&::-webkit-scrollbar': {
                                width: '7px',
                                borderRadius: '10px',
                                backgroundColor: `rgba(0, 0, 0, 0.1)`,
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: `rgba(0, 0, 0, 0.4)`,
                                borderRadius: '10px',
                              },
                            }}>
                            {controller.loadingData ? (
                              <Center my={4}>
                                <Spinner size="md" />
                              </Center>
                            ) : !controller.placesSearchResults ? null : controller
                                .placesSearchResults?.length > 0 ? (
                              controller.placesSearchResults.map(
                                (place, key) => (
                                  <Button
                                    w={'100%'}
                                    maxW={'100%'}
                                    variant="ghost"
                                    rounded={'none'}
                                    justifyContent={'flex-start'}
                                    onClick={() => {
                                      formik.setFieldValue(
                                        'address',
                                        place.formatted_address
                                      );

                                      formik.setFieldValue(
                                        'point',
                                        place.geometry.location
                                      );
                                      onClose();
                                    }}
                                    key={'TypeItem-' + key}
                                    isTruncated>
                                    <Text isTruncated fontWeight={'normal'}>
                                      {place.formatted_address}
                                    </Text>
                                  </Button>
                                )
                              )
                            ) : (
                              <Text my={4}>Sin resultados</Text>
                            )}
                          </PopoverBody>
                        </PopoverContent>
                      </Box>
                    )}
                  </Popover>
                  {getIn(formik.errors, 'type') ? (
                    <FormErrorMessage fontSize={'x-small'}>
                      {getIn(formik.errors, 'type')}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
                <FormControl
                  as={GridItem}
                  colSpan={{ sm: 6, md: 6 }}
                  style={{ borderRadius: 50 }}>
                  <FormLabel fontSize={{ sm: 'x-small', md: 'smaller' }}>
                    Punto en el Mapa
                  </FormLabel>
                  <Box
                    borderRadius={20}
                    overflow="hidden"
                    borderWidth={1.5}
                    borderColor="gray.500">
                    <MapComponent
                      height={'25vh'}
                      center={
                        formik.values.point.lat && formik.values.point.lng
                          ? {
                              lat: formik.values.point.lat,
                              lng: formik.values.point.lng,
                            }
                          : null
                      }
                      zoom={
                        formik.values.point.lat && formik.values.point.lng
                          ? 15
                          : null
                      }
                      options={{
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        styles: [
                          {
                            featureType: 'poi',
                            stylers: [{ visibility: 'off' }],
                          },
                          {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }],
                          },
                          {
                            featureType: 'poi',
                            elementType: 'labels',
                            stylers: [{ visibility: 'off' }],
                          },
                          {
                            featureType: 'poi.business',
                            elementType: 'labels',
                            stylers: [{ visibility: 'off' }],
                          },
                        ],
                      }}>
                      {formik.values.point.lat && formik.values.point.lng && (
                        <Marker
                          key={'New-BP'}
                          position={{
                            lat: formik.values.point.lat,
                            lng: formik.values.point.lng,
                          }}
                        />
                      )}
                    </MapComponent>
                  </Box>
                </FormControl>
              </SimpleGrid>
              <GridItem colSpan={2}>
                <Button
                  w="100%"
                  bgColor={'#313149'}
                  color="white"
                  rounded="full"
                  disabled={
                    !(formik.values.point.lat && formik.values.point.lng)
                  }
                  onClick={action}>
                  Siguiente
                </Button>
              </GridItem>
            </SimpleGrid>
          </Stack>
        </Flex>
        <Center h="80vh" w="40%">
          <Image
            alt={'Step Image'}
            src={AddBikletaPointSteps[1]}
            maxH="100%"
            objectFit={'contain'}
          />
        </Center>
      </Stack>
    </FormikProvider>
  );
};

const ThirdStepItem: React.FC<StepItem> = ({ formik, action }) => {
  const toast = useToast();
  const [imageArray, setImageArray] = useState<any[]>([]);
  return (
    <FormikProvider value={formik}>
      <Stack w="100%" direction={{ base: 'column', md: 'row-reverse' }} px="2%">
        <Flex
          w="60%"
          py={8}
          flex={1}
          align={'center'}
          justify={'left'}
          pos="relative">
          <Stack w="100%">
            <SimpleGrid
              display={'grid'}
              w="100%"
              columns={{ md: 7 }}
              spacing={{ sm: 2, md: 3 }}
              px={0}>
              <Stack textAlign={'left'} as={GridItem} colSpan={5} spacing={2}>
                <Text fontSize={'2xl'}>Añade fotos de tu comercio</Text>
                <Text fontSize={'sm'}>
                  Muestra fotos atractivas en las que se vean los productos y
                  actividades que tu comercio ofrece
                </Text>
              </Stack>
              <FormControl
                as={GridItem}
                colSpan={6}
                isInvalid={getIn(formik.errors, 'photos')}
                isRequired>
                <FormLabel htmlFor="photos" fontSize={'x-small'}>
                  Fotos (Máximo 5)
                </FormLabel>
                <Field
                  as={FilePond}
                  key="images"
                  instantUpload={false}
                  upload="false"
                  files={formik.values.photos}
                  maxFileSize={'3MB'}
                  labelMaxFileSizeExceeded="Archivo demasiado grande"
                  labelMaxFileSize="Tamaño máximo: 3MB"
                  allowMultiple
                  maxFiles={5}
                  maxParallelUploads={5}
                  imagePreviewMaxHeight={120}
                  acceptedFileTypes={['image/*']}
                  onupdatefiles={images => {
                    setImageArray(images);
                    formik.setFieldValue('photos', images);
                  }}
                  labelIdle='Arrastre las imagenes o <span class="filepond--label-action">elijalas desde archivo</span>'
                  labelFileLoading="Cargando"
                  labelFileProcessing="Procesando"
                  labelFileProcessingComplete="Procesado"
                  labelFileProcessingError="Error"
                  labelTapToCancel="Cancelar"
                  labelTapToRetry="Reintentar"
                  labelTapToUndo="Deshacer"
                  labelButtonRemoveItem="Eliminar"
                  labelFileLoadError={'Error al cargar el archivo'}
                  onwarning={error => {
                    toast({
                      title: 'Limite de imagenes excedido',
                      description: 'Solo se pueden subir 5 imagenes',
                      status: 'info',
                      duration: 5000,
                      isClosable: true,
                      position: 'top',
                    });
                  }}
                  onerror={error => {
                    formik.setFieldError('photos', error.main);
                  }}
                />
                {getIn(formik.errors, 'photos') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(formik.errors, 'photos')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <GridItem colSpan={2}>
                <Button
                  w="100%"
                  bgColor={'#313149'}
                  color="white"
                  rounded="full"
                  onClick={action}
                  disabled={imageArray.length < 1}>
                  Siguiente
                </Button>
              </GridItem>
            </SimpleGrid>
          </Stack>
        </Flex>
        <Center h="80vh" w="40%">
          <Image
            alt={'Step Image'}
            src={AddBikletaPointSteps[2]}
            maxH="100%"
            objectFit={'contain'}
          />
        </Center>
      </Stack>
    </FormikProvider>
  );
};

const FourthStepItem: React.FC<StepItem> = ({ formik, action, controller }) => {
  const countryOptions = COUNTRIES.map(({ name, iso, prefix }) => ({
    label: name,
    value: iso,
    prefix: prefix,
  }));

  return (
    <FormikProvider value={formik}>
      <Stack w="100%" direction={{ base: 'column', md: 'row-reverse' }} px="2%">
        <Flex
          w="60%"
          py={8}
          flex={1}
          align={'center'}
          justify={'left'}
          pos="relative">
          <Stack w="100%">
            <SimpleGrid
              display={'grid'}
              w="100%"
              columns={{ md: 7 }}
              spacing={{ sm: 2, md: 5 }}
              px={0}>
              <Stack textAlign={'left'} as={GridItem} colSpan={4} spacing={5}>
                <Text fontSize={'3xl'}>Añade información de contacto</Text>
                <Text fontSize={'md'}>
                  Ingresa número de telefóno de tu comercio, sitio web y
                  dirección de correo electrónico para que puedan contactarte
                </Text>
              </Stack>

              <FormControl as={GridItem} colSpan={6}>
                <PhoneNumberInput
                  inputProps={{
                    variant: 'filled',
                    size: 'lg',
                    placeholder: 'Número de Teléfono',
                  }}
                  options={countryOptions}
                  onChange={(option: Option) => {
                    formik.setFieldValue(
                      'phone',
                      controller.getInfoFromPhoneNumber(option).phoneNumber
                    );
                    formik.setFieldValue(
                      'phoneRegion',
                      controller.getInfoFromPhoneNumber(option).region
                    );
                  }}
                  country={''}
                />
              </FormControl>

              <FormControl
                isInvalid={getIn(formik.errors, 'email')}
                isRequired
                as={GridItem}
                colSpan={6}>
                <Field
                  as={Input}
                  variant="filled"
                  size="lg"
                  placeholder="Email"
                  id="email"
                  name="email"
                  type="text"
                />
                {getIn(formik.errors, 'email') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(formik.errors, 'email')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>

              <FormControl
                isInvalid={getIn(formik.errors, 'web')}
                isRequired
                as={GridItem}
                colSpan={6}>
                <Field
                  as={Input}
                  variant="filled"
                  size="lg"
                  placeholder="Sitio Web"
                  id="web"
                  name="web"
                  type="text"
                />
                {getIn(formik.errors, 'web') ? (
                  <FormErrorMessage fontSize={'x-small'}>
                    {getIn(formik.errors, 'web')}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <GridItem colSpan={2}>
                <Button
                  disabled={!(formik.values.email !== '')}
                  w="100%"
                  bgColor={'#313149'}
                  color="white"
                  rounded="full"
                  onClick={action}>
                  Siguiente
                </Button>
              </GridItem>
            </SimpleGrid>
          </Stack>
        </Flex>
        <Center h="80vh" w="40%">
          <Image
            alt={'Step Image'}
            src={AddBikletaPointSteps[3]}
            maxH="100%"
            objectFit={'contain'}
          />
        </Center>
      </Stack>
    </FormikProvider>
  );
};

const FifthStepItem: React.FC<StepItem> = ({ formik, action }) => {
  return (
    <FormikProvider value={formik}>
      <Stack
        w="100%"
        direction={{ base: 'column', md: 'row-reverse' }}
        px="2%"
        maxH={'87vh'}
        overflowY={'scroll'}>
        <Flex w="60%" py={8} flex={1} justify={'left'} pos="relative">
          <Stack w="100%">
            <SimpleGrid
              display={'grid'}
              w="100%"
              columns={{ md: 7 }}
              spacing={{ sm: 2, md: 10 }}
              px={0}>
              <Stack textAlign={'left'} as={GridItem} colSpan={4} spacing={5}>
                <Text fontSize={'3xl'}>Añade horario comercial</Text>
              </Stack>
              <FormControl
                isInvalid={getIn(formik.errors, 'name')}
                isRequired
                as={GridItem}
                colSpan={6}>
                <Stack spacing={3}>
                  {daysArray.map((day, index) => (
                    <div key={'itemHour-' + index}>
                      <OpenTimeCheck
                        dayName={day}
                        index={index + 1}
                        formik={formik}
                      />
                    </div>
                  ))}
                </Stack>
              </FormControl>

              <GridItem colSpan={2}>
                <Button
                  w="100%"
                  bgColor={'#313149'}
                  color="white"
                  rounded="full"
                  onClick={action}
                  mb="10%"
                  isLoading={formik.isSubmitting}
                  disabled={
                    !Boolean(
                      Object.keys(formik.values.workTime)
                        .map(key => {
                          const workTimeItem = formik.values.workTime[key];
                          return !!(
                            workTimeItem.opens &&
                            workTimeItem.openTime &&
                            workTimeItem.closeTime
                          );
                        })
                        .find(item => item === true)
                    )
                  }>
                  Finalizar
                </Button>
              </GridItem>
            </SimpleGrid>
          </Stack>
        </Flex>
        <Center h="80vh" w="40%">
          <Image
            alt={'Step Image'}
            src={AddBikletaPointSteps[4]}
            maxH="100%"
            objectFit={'contain'}
          />
        </Center>
      </Stack>
    </FormikProvider>
  );
};

const ModalAddForm: React.FC<AddBikletaPointProps> = ({
  isOpen,
  onClose,
  useController = useFormSectionController,
}) => {
  const [swiperController, setSwiperController] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useToast();
  const service = useBikletaPointsService();
  const controller = useController();

  const { setSessionBPs, setActivePoint } = useStore();

  const {
    isOpen: isLogOutModalOpen,
    onOpen: onLogOutModalOpen,
    onClose: onLogOutModalClose,
  } = useDisclosure();

  const handleSubmit = async values => {
    const photosCopy = values.photos;

    delete values.photos;

    console.log('Values to post: ', values);
    try {
      if (values.point.lat === undefined || values.point.lng === undefined) {
        throw new Error('Porfavor, ubique el Negocio en la sección del mapa');
      }
      //console.log(values);

      Object.keys(values.workTime).forEach(function (key, index) {
        //console.log('Log day:', values.workTime[key]);

        if (values.workTime[key].opens) {
          if (!values.workTime[key].openTime || !values.workTime[key].closeTime)
            throw new Error(
              'Los dias que abre el negocio deben tener horario de apertura y cierre'
            );
        }
      });

      // Post images

      const formData = new FormData();

      let addedPoint = null;

      await service.registerPoint(values).then(async res => {
        console.log('Backennd response: ', res);

        addedPoint = res;
        const commerceId = res._id;
        for (let i = 0; i < photosCopy.length; i++) {
          console.log(photosCopy[i].file);
          formData.append(`photos[${i}]`, photosCopy[i].file);
        }

        await service
          .postPhotos(formData, commerceId)
          .then(res => console.log(res));
      });

      toast({
        title: `Comercio Adherido!`,
        description: `${values.name} se agrego exitosamente a Bikleta.`,
        status: 'success',
        position: 'top',
        isClosable: true,
      });

      addedPoint && setSessionBPs([...sessionBikletaPoints, addedPoint]);
      addedPoint && setActivePoint(addedPoint);
      onClose();
    } catch (error) {
      console.error(error);

      if (
        error?.message === 'Porfavor ubique el Negocio en la sección del mapa'
      ) {
        toast({
          title: `Faltan datos!`,
          description: error.message,
          status: 'error',
          position: 'top',
          isClosable: true,
        });
      } else {
        toast({
          title: `Ups, ocurrió un error!`,
          description:
            error.message ?? 'Ocurrió un error al cargar el negocio.',
          status: 'error',
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      photos: undefined,
      name: '',
      phoneRegion: '',
      phone: '',
      email: '',
      workTime: {
        1: {
          dayNumber: 1,
          opens: false,
          openTime: undefined,
          closeTime: undefined,
        },
        2: {
          dayNumber: 2,
          opens: false,
          openTime: undefined,
          closeTime: undefined,
        },
        3: {
          dayNumber: 3,
          opens: false,
          openTime: undefined,
          closeTime: undefined,
        },
        4: {
          dayNumber: 4,
          opens: false,
          openTime: undefined,
          closeTime: undefined,
        },
        5: {
          dayNumber: 5,
          opens: false,
          openTime: undefined,
          closeTime: undefined,
        },
        6: {
          dayNumber: 6,
          opens: false,
          openTime: undefined,
          closeTime: undefined,
        },
        7: {
          dayNumber: 7,
          opens: false,
          openTime: undefined,
          closeTime: undefined,
        },
      },
      web: '',
      address: '',
      point: {
        lat: null,
        lng: null,
      },
      type: '',
    },
    validationSchema: SignupSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const { activePoint, sessionBikletaPoints } = useStore();

  return (
    <>
      <Drawer
        onClose={() => {
          onClose();
          formik.resetForm();
        }}
        size={'full'}
        closeOnEsc={!!activePoint}
        isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            textAlign={'center'}
            fontWeight="normal"
            shadow={'md'}
            bgColor="backgroundColor.100">
            <IconButton
              aria-label="back"
              pos={'absolute'}
              left="2%"
              alignSelf={'center'}
              size={'md'}
              rounded="full"
              icon={<ArrowBackIcon />}
              onClick={() => {
                swiperController.slidePrev();
              }}
              hidden={activeIndex === 0}
            />
            Agregar Bikleta Point
            {sessionBikletaPoints?.length === 0 && (
              <Button
                aria-label="exit"
                colorScheme={'red'}
                pos={'absolute'}
                right="2%"
                alignSelf={'center'}
                size={'md'}
                leftIcon={<BiLogOut />}
                onClick={onLogOutModalOpen}>
                Salir
              </Button>
            )}
          </DrawerHeader>
          {sessionBikletaPoints?.length > 0 && <DrawerCloseButton />}
          <DrawerBody p={0} bgColor="backgroundColor.50">
            <Stack
              maxH={'87vh'}
              direction={{ base: 'column', md: 'row-reverse' }}
              py={5}>
              <Swiper
                className="mySwiper"
                loop={false}
                allowTouchMove={false}
                onSwiper={setSwiperController}
                onSlideChange={swiper => {
                  setActiveIndex(swiper.activeIndex);
                }}
                preventClicks={false}
                preventClicksPropagation={false}
                modules={[Navigation, Controller]}>
                <SwiperSlide>
                  <FirstStepItem
                    formik={formik}
                    action={() => swiperController.slideNext()}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <SecondStepItem
                    formik={formik}
                    action={() => swiperController.slideNext()}
                    controller={controller}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ThirdStepItem
                    formik={formik}
                    action={() => swiperController.slideNext()}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <FourthStepItem
                    formik={formik}
                    action={() => swiperController.slideNext()}
                    controller={controller}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <FifthStepItem
                    formik={formik}
                    action={() => formik.submitForm()}
                  />
                </SwiperSlide>
              </Swiper>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <LogOutmodal isOpen={isLogOutModalOpen} onClose={onLogOutModalClose} />
    </>
  );
};

export default ModalAddForm;
