import { useEffect, useState } from 'react';
import { useBikletaPointsService } from '../../../services/bikletaPoints/bikletaPointsService';
import { POInew } from '../../../services/bikletaPoints/dto';
import { centerDef, PointTypes } from '../../../utils/constants';
import { useAsync } from '../../../utils/useAsync';
import { BikletaPointsMapController, UserLocationProps } from '../interfaces';

export const useMapViewController = (): BikletaPointsMapController => {
  const [focusedPoint, setFocusedPoint] = useState(null);
  const service = useBikletaPointsService();

  const [searchInput, setSearchInput] = useState(null);

  const [data, , loading, execute, refreshing, refresh] = useAsync(
    service.fetchPOIs,
    [] as any,
    true
  );

  const initViewMethod = () => {
    execute();
  };

  useEffect(() => {
    refresh(searchInput);
  }, [searchInput]);

  const mapPoints = (elements?: POInew[]) =>
    elements?.map(item => {
      return {
        ...item,
        type:
          PointTypes.find(e => item.type === e.value)?.label ?? 'Bikleta Point',
      };
    });

  const [userLocation, setUserLocation] = useState<UserLocationProps | null>(null);

  useEffect(() => {
    if (!userLocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(res =>
          setUserLocation({
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          })
        );
      } else {
        setUserLocation(centerDef);
      }
    }
  }, [userLocation]);

  return {
    initView: initViewMethod,
    focusedPoint,
    setFocusedPoint,
    data: mapPoints(data) || [],
    loading,
    refresh,
    refreshing,
    setSearchInput,
    centerPoint: userLocation,
  };
};
