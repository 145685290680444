import Home from './views/Landing/Home';
import PointsView from './views/MapViews/Points/mapview.view';
import BikletasView from './views/MapViews/Bikletas/mapview.view';
import DownloadPage from './views/Landing/DownloadPage';
import { FaCog } from 'react-icons/fa';

import SignIn from './views/Pages/login/login.view';
import SignUp from './views/Pages/register/signup.view';
import ConfigView from './views/Admin/Config/config.view';
import { MdLocalOffer, MdRoomService } from 'react-icons/md';
import ServicesView from './views/Admin/Services/services.view';
import BenefitsView from './views/Admin/Benefits/benefits.view';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import Dashboard from './views/Admin/Dashboard/dashboard.view';
import { Navigate, RouteObject } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import AdminLayout from './layouts/Admin';
import PrivateRoute from './components/PrivateRoute';
import LandingLayout from './layouts/Landing';
import OldSharedTrip from './views/Landing/SharedTrip';
import { ResetPassword } from './views/Pages/reset-password/reset-password.view';
import { AddView } from './views/Admin/Benefits/sections/AddView/add.view';
import { EditView } from './views/Admin/Benefits/sections/EditView/edit.view';
import { SpendView } from './views/Admin/Benefits/sections/SpendView/spend.view';

export const WebRoutes: RouteObject[] = [
  {
    path: '/',
    element: <LandingLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'download',
        element: <DownloadPage />,
      },
      {
        path: 'descargar',
        element: <DownloadPage />,
      },
    ],
  },
  {
    path: '/admin',
    element: <PrivateRoute component={AdminLayout} />,
    children: [
      {
        index: true,
        element: <Navigate to="dashboard" />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'benefits',
        children: [
          {
            index: true,
            element: <BenefitsView />,
          },
          {
            path: 'add',
            element: <AddView />,
          },
          {
            path: 'edit',
            element: <EditView />,
          },
          {
            path: 'spend',
            element: <SpendView />,
          },
        ],
      },
      {
        path: 'services',
        element: <ServicesView />,
      },
      {
        path: 'settings',
        element: <ConfigView />,
      },
    ],
  },
  {
    path: '/recorrido/:id',
    element: <OldSharedTrip />,
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'signin',
        element: <SignIn />,
      },
      {
        path: 'signup',
        element: <SignUp />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        index: true,
        element: <Navigate to="signin" />,
      },
    ],
  },
  {
    path: '/map',
    children: [
      {
        path: 'points',
        element: <PointsView />,
      },
      {
        path: 'bikletas',
        children: [
          {
            index: true,
            element: <BikletasView />,
          },
          {
            path: ':bikeId',
            element: <BikletasView />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={'/'} />,
  },
];

export const adminRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <RiLayoutMasonryFill color="inherit" />,
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/benefits',
    name: 'Beneficios',
    icon: <MdLocalOffer color="inherit" />,
    component: BenefitsView,
    category: 'section',
    views: [
      {
        path: '/spend',
        name: 'Consumir cupon',
        layout: '/admin',
      },
    ],
    layout: '/admin',
  },
  {
    name: 'Servicios',
    path: '/services',
    layout: '/admin',
    icon: <MdRoomService color="inherit" />,
    component: ServicesView,
  },
  {
    path: '/settings',
    name: 'Ajustes',
    icon: <FaCog color="inherit" />,
    component: ConfigView,
    layout: '/admin',
  },
];
