import axios from 'axios';
import { useContext } from 'react';
import { FormValues } from '../../components/Admin/FormSection/interfaces';
import { appUrl } from '../../conf';
import { AxiosContext } from '../../providers/axiosContext';
import { BenefitsType, ImageDTO, POI } from './dto';
import { ActualFileObject } from 'filepond';
import { useBenefitHistoryStore } from '../benefitsService/benefit.store';
import {
  BenefitDTO,
  BenefitHistoryItem,
  CouponDTO,
} from '../benefitsService/benefit.dto';

export interface BikletaPointsService {
  fetchPOIs: (params?: any) => Promise<POI[]>;
  registerPoint: (data: any) => Promise<any>;
  postPhotos: (data: any, id: string) => Promise<any>;
  getMyBPs: () => Promise<any>;
  editPointData: (idPoint: string, data: Partial<FormValues>) => Promise<any>;
  updateService: (pointId: string, serviceData: any) => Promise<any>;
  updateBenefit: (benefitId: string, benefitData: any) => Promise<any>;
  createBenefit: (pointId: string, benefitData: any) => Promise<any>;
  deleteBenefit: (pointId: string, benefitsTodelete: string[]) => Promise<any>;
  getStatistics: (idPoint: string) => Promise<any>;
  getBenefits: (idPoint: string) => Promise<BenefitsType[]>;
  postImage: (image: ActualFileObject) => Promise<ImageDTO>;
  checkCoupon: (code: string) => Promise<any>;
}

export const useBikletaPointsService = (): BikletaPointsService => {
  const axiosContext = useContext(AxiosContext);

  const fetchPOIs = async search => {
    const res = await axios.get(appUrl + '/web/searchEngine/commerce', {
      params: {
        search,
      },
    });

    return res.data;
  };

  const getMyBPs = async () => {
    const res = await axiosContext.authAxios.get('/web/bikleta-points');
    return res.data;
  };

  const editPointData = async (pointId, data) => {
    const res = await axiosContext.authAxios.put(
      '/web/bikleta-points/' + pointId,
      data
    );

    return res.data;
  };

  const registerPoint = async data => {
    const res = await axiosContext.authAxios.post('/web/bikleta-points', data);
    return res.data;
  };

  const updateService = async (pointId: string, serviceData) => {
    const res = await axiosContext.authAxios.put(
      '/web/bikleta-points/' + pointId + '/services',
      {
        ...serviceData,
      }
    );

    return res;
  };

  const updateBenefit = async (benefitId: string, benefitData) => {
    const res = await axiosContext.authAxios.put(
      '/api/v1/benefits/' + benefitId,
      {
        ...benefitData,
      }
    );

    return res.data;
  };

  const createBenefit = async (pointId: string, benefitData) => {
    const res = await axiosContext.authAxios.post(
      '/api/v1/benefits/point/' + pointId,
      {
        ...benefitData,
      }
    );

    return res.data;
  };

  const deleteBenefit = async (
    pointId: string,
    benetefitsToDelete: string[]
  ) => {
    const res = await axiosContext.authAxios.delete(
      // '/bikleta-points/' + pointId + '/benefits/delete',
      '/api/v1/benefits/delete',
      {
        data: {
          benefitsId: benetefitsToDelete,
        },
      }
    );

    return res.data;
  };

  const postPhotos = async (data, id) => {
    const res = await axiosContext.authAxios.post(
      '/web/bikleta-points/' + id + '/images',
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return res.data;
  };

  const getStatistics = async (id: string) => {
    const res = await axiosContext.authAxios.get(
      '/web/bikleta-points/' + id + '/statistics'
    );
    //console.log(res.data);

    return res.data;
  };

  const getBenefits = async (id: string) => {
    const res = await axiosContext.authAxios.get(
      '/api/v1/benefits/point/' + id + '/all',
      {
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      }
    );

    return res.data;
  };

  const postImage = async (image: ActualFileObject) => {
    const formData = new FormData();
    formData.append('files', image);
    const res = await axiosContext.authAxios.post<ImageDTO[]>(
      '/api/v1/benefits/photos',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data[0];
  };

  const checkCoupon = async (code: string): Promise<CouponDTO> => {
    const res = await axiosContext.authAxios.delete(`/api/v1/coupons/${code}`);
    return {
      ...res.data,
      startValidDate: new Date(res.data.startValidDate),
      expirationDate: new Date(res.data.expirationDate),
    };
  };

  return {
    fetchPOIs,
    registerPoint,
    postPhotos,
    getMyBPs,
    editPointData,
    updateService,
    updateBenefit,
    createBenefit,
    deleteBenefit,
    getStatistics,
    getBenefits,
    postImage,
    checkCoupon,
  };
};
