import { useAsync } from '../../../utils/useAsync';
import { ConfigControllerProps } from './interfaces';
import { usePlacesSearchService } from '../../../services/placesService/placesService';
import { useBikletaPointsService } from '../../../services/bikletaPoints/bikletaPointsService';
import { useState } from 'react';
import { useStore } from '../../../utils/useStore';

export const useConfigViewController = (): ConfigControllerProps => {
  const service = usePlacesSearchService();
  const bpService = useBikletaPointsService();
  const [isLoading, setIsLoading] = useState(false);
  const { updateBikletaPoint } = useStore();
  const [resultsData, , loadingResults, executeSearch, ,] = useAsync(
    service.fetchResults,
    null
  );

  const getInfoFromPhoneNumber = number => {
    const sections = number.split(' ');
    // console.log(sections, sections.length);
    if (sections.length === 1) {
      return {
        phoneNumber: sections[0],
      };
    }
    return {
      region: sections[0],
      phoneNumber: sections.slice(1, sections.length).join(''),
    };
  };

  const submitEditedData = async (id: string, data: any) => {
    try {
      setIsLoading(true);
      const res = await bpService.editPointData(id, data);

      updateBikletaPoint({
        ...res,
        name: data.name,
        type: data.type,
        email: data.email,
        phone: data.phone,
        phoneRegion: data.phoneRegion,
        address: data.address,
        point: data.point,
        web: data.web,
      });
    } catch (error) {
      console.error('Error edit: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    setSearchQuery: executeSearch,
    loadingData: loadingResults,
    placesSearchResults: resultsData?.results,
    getInfoFromPhoneNumber,
    submitEditedData,
    isSubmitting: isLoading,
  };
};
