import { useEffect, useState } from 'react';
import { useBikesService } from '../../../services/bikesService/bikesService';
import { BikeDTO } from '../../../services/bikesService/dto';
import { centerDef } from '../../../utils/constants';
import { useAsync } from '../../../utils/useAsync';
import { BikesMapController, UserLocationProps } from '../interfaces';
import { useParams } from 'react-router-dom';

export const useMapViewController = (): BikesMapController => {
  const { bikeId } = useParams();
  const [focusedPoint, setFocusedPoint] = useState(null);
  const service = useBikesService();

  const [searchInput, setSearchInput] = useState<string | null>(null);

  const [data, , loading, execute, refreshing, refresh] = useAsync(
    service.fetchBikes,
    [] as any[],
    true
  );

  const initViewMethod = () => {
    execute();
  };

  useEffect(() => {
    refresh(searchInput);
  }, [searchInput]);

  const mapPoints = (elements?: BikeDTO[]) =>
    elements?.map(item => {
      return {
        ...item,
      };
    });

  useEffect(() => {
    if (bikeId && data && data?.length > 0) {
      const paramBikeItem = data.find(item => item._id === bikeId);
      paramBikeItem && setFocusedPoint(paramBikeItem);
    }
  }, [data]);

  const [userLocation, setUserLocation] = useState<UserLocationProps | null>(null);

  useEffect(() => {
    //console.log('user Location: ', userLocation);

    if (!userLocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(res =>
          setUserLocation({
            lat: res.coords.latitude,
            lng: res.coords.longitude,
          })
        );
      } else {
        setUserLocation(centerDef);
      }
    }
  }, [userLocation]);

  return {
    initView: initViewMethod,
    focusedPoint,
    setFocusedPoint,

    data: mapPoints(data) || [],
    loading,
    refresh,
    refreshing,

    setSearchInput,

    centerPoint: userLocation,
  };
};
