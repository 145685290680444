import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Skeleton,
  Button,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import IconBox from '../../Icons/IconBox';
import { POInew } from '../../../services/bikletaPoints/dto';

interface SidebarItemProps {
  activePoint: POInew;
  route: any;
  key: any;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  activePoint,
  route,
  key,
}) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  let activeBg = useColorModeValue('white', '#313149');
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue('gray.400', 'gray.400');
  let sidebarActiveShadow = '0px 7px 11px rgba(0, 0, 0, 0.04)';
  let variantChange = '0.2s linear';

  // Here are all the props that may change depending on sidebar's state.(Opaque or transparent)
  let location = useLocation();

  const activeRoute = routeName => {
    return location.pathname === routeName ? 'active' : '';
  };

  var isActive = '/' + location.pathname.split('/')[2] === route.path;

  const navigate = useNavigate();

  useEffect(() => {
    if (isActive && route.category === 'section') {
      onOpen();
    } else {
      onClose();
    }
  }, [isActive]);

  return route.category !== 'section' ? (
    <Skeleton isLoaded={!!activePoint} key={'section-' + key}>
      <NavLink to={route.layout + route.path}>
        {activeRoute(route.layout + route.path) === 'active' ? (
          <Button
            boxSize="initial"
            justifyContent="flex-start"
            alignItems="center"
            boxShadow={sidebarActiveShadow}
            bg={activeBg}
            transition={'0.2s linear'}
            mb={{
              xl: '12px',
            }}
            mx={{
              xl: 'auto',
            }}
            ps={{
              sm: '10px',
              xl: '16px',
            }}
            py="7px"
            borderRadius="12px"
            w="100%"
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.04)',
            }}>
            <Flex>
              {typeof route.icon === 'string' ? (
                <Icon>{route.icon}</Icon>
              ) : (
                <IconBox
                  bg="transparent"
                  color="brand.50"
                  h="30px"
                  w="30px"
                  me="12px"
                  transition={variantChange}>
                  {route.icon}
                </IconBox>
              )}
              <Text color={activeColor} my="auto" fontSize="sm">
                {route.name}
              </Text>
            </Flex>
          </Button>
        ) : (
          <Button
            boxSize="initial"
            key={'section-' + key}
            justifyContent="flex-start"
            alignItems="center"
            bg="transparent"
            mb={{
              xl: '12px',
            }}
            mx={{
              xl: 'auto',
            }}
            py="7px"
            ps={{
              sm: '10px',
              xl: '16px',
            }}
            borderRadius="12px"
            w="100%"
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}>
            <Flex>
              {typeof route.icon === 'string' ? (
                <Icon>{route.icon}</Icon>
              ) : (
                <IconBox
                  bg={'transparent'}
                  color="brand.50"
                  h="30px"
                  w="30px"
                  me="12px"
                  transition={variantChange}>
                  {route.icon}
                </IconBox>
              )}
              <Text color={inactiveColor} my="auto" fontSize="sm">
                {route.name}
              </Text>
            </Flex>
          </Button>
        )}
      </NavLink>
    </Skeleton>
  ) : (
    <Skeleton isLoaded={!!activePoint} key={'section-' + key}>
      <Button
        rightIcon={<ChevronRightIcon opacity={0.5} />}
        onClick={() => {
          // isActive && onToggle();
          navigate(route.layout + route.path);
        }}
        boxSize="initial"
        boxShadow={isActive ? '0px 7px 11px rgba(0, 0, 0, 0.04)' : 'none'}
        justifyContent="flex-start"
        alignItems="center"
        bg={'transparent'}
        transition={'0.2s linear'}
        mb={{
          xl: '12px',
        }}
        mx={{
          xl: 'auto',
        }}
        py="12px"
        ps={{
          sm: '10px',
          xl: '16px',
        }}
        borderRadius="15px"
        w="100%"
        _active={{
          bg: 'inherit',
          transform: 'none',
          borderColor: 'transparent',
        }}
        _focus={{
          boxShadow: isActive ? '0px 7px 11px rgba(0, 0, 0, 0.04)' : 'none',
        }}>
        <Flex>
          <IconBox
            bg={'transparent'}
            color="brand.50"
            h="30px"
            w="30px"
            me="12px"
            transition={variantChange}>
            {route.icon}
          </IconBox>
          <Text
            color={inactiveColor}
            my="auto"
            fontSize="sm"
            fontWeight={'bold'}>
            {route.name}
          </Text>
        </Flex>
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {route.views.map((item, key) => {
          return (
            <Button
              key={key}
              leftIcon={item.icon}
              w="full"
              justifySelf={'flex-end'}
              _focus={{
                boxShadow: isActive
                  ? '0px 7px 11px rgba(0, 0, 0, 0.04)'
                  : 'none',
              }}
              mb={{
                xl: '12px',
              }}
              mx={{
                xl: 'auto',
              }}
              py="12px"
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              as={NavLink}
              fontSize={'sm'}
              variant={'ghost'}
              to={item.layout + route.path + item.path}>
              <Text color={inactiveColor} fontSize="sm">
                {item.name}
              </Text>
            </Button>
          );
        })}
      </Collapse>
    </Skeleton>
  );
};

export default SidebarItem;
